import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Input, FormGroupList, Dialog } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { systemLogin, resetPassword, changePassword, toggleChangePWDDialog } = iceStarkData.store.get('commonAction')?.users;
import './SystemLogin.scss';
import JSEncrypt from '@/libs/encrypt.js';

const required = (text) => (value) => {
  return (!value || (value + '').trim() === '' ? text : undefined);
  // return (value || typeof value === 'number' ? undefined : '请填写备注');
}

const requiredPassword = required('请输入密码');
const requiredPassword1 = (value) =>{
    let reg = /^(?!^.*[\u4E00-\u9FA5].*$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*((?=[\x21-\x7e]+)[^A-Za-z0-9]).*)\S{8,30}$/
    if(!reg.test(value.trim())){
            return '密码为包含数字、大小写字母、特殊字符任意三种组合，长度大于8，小于30的字符';
    }else{
      return 
    }
}
const validateComfirmPWD = (value, values) =>{

  if(!value){
    return "请输入确认密码";
  } else if( values.newPassword && value != values.newPassword){
    return "确认密码与新密码不一致，请重新输入";
  } else{
    return 
  }
}


@withRouter
@connect(
  state => ({
    form: state.form.SystemLoginForm,
    showChangePWDDialog: state.users.showChangePWDDialog
  }),
  {
    systemLogin: systemLogin.REQUEST,
    changePassword: changePassword.REQUEST,
    resetPassword: resetPassword.REQUEST,
    toggleChangePWDDialog,
  }
)
class Resetpwd extends React.Component {

  onSubmit = (values) => {
    const {ischangepwd = false, form = {values: {}}} = this.props;

    var encrypt  = new JSEncrypt();
    encrypt.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQqJ4SyujGCKwQvNzsKVMcoSQnoE60zKSPoUen7c+FRqJqineEFNhk7Hc8qWfZDbzMtIAMeg78+SgiOJ1es+KOIUOncaN0dae34QP5AdmORAm1AUXG8osmCe54SU0cnnIwS6L6RE6OIiFZzf//Eo3blf1x3sBLc6WZ/KDSqRLKCwIDAQAB`);

    if(ischangepwd){
      this.props.changePassword({
        oldPassword: encrypt.encrypt(values.oldPassword),
        newPassword: encrypt.encrypt(values.newPassword)
      });
    }else {
      this.props.resetPassword({
        emailAccount: form.values.account || '',
        newPassword: values.newPassword
      });
    }

  }

  render() {
    const {handleSubmit, ischangepwd = false, toggleChangePWDDialog} = this.props;
    return <Dialog
      className="resetpwdform"
      style={{ width: '480px' }}
      title="重置密码"
      visible={true}
      okText="确认"
      cancelText="取消"
      onOk={handleSubmit(this.onSubmit)}
      onCancel={()=>{toggleChangePWDDialog(false)}}
    >
      <div className="form">
        <FormGroupList>
          {ischangepwd && <FormGroupField
            name="oldPassword"
            label="旧密码"
            type="password"
            component={Input}
            validate={[requiredPassword]}
          ></FormGroupField>}

          <FormGroupField
            name="newPassword"
            label="新密码"
            type="password"
            component={Input}
            validate={[requiredPassword,requiredPassword1]}
          ></FormGroupField>

          <FormGroupField
            name="confirmpassword"
            label="确认密码"
            type="password"
            component={Input}
            validate={validateComfirmPWD}
          ></FormGroupField>
        </FormGroupList>
      </div>

    </Dialog>
  }
}

let ResetpwdForm = reduxForm({
  form: 'resetpwdForm', // a unique identifier for this form
  enableReinitialize: true,
})(Resetpwd)

export default ResetpwdForm;
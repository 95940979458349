import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Button } from 'tyb'

function inject_unount(target) {
  // 改装componentWillUnmount，销毁的时候记录一下
  let next = target.prototype.componentWillUnmount
  target.prototype.componentWillUnmount = function () {
    if (next) next.call(this, ...arguments);
    this.unmount = true
  }
  // 对setState的改装，setState查看目前是否已经销毁
  let setState = target.prototype.setState
  target.prototype.setState = function () {
    if (this.unmount) return;
    setState.call(this, ...arguments)
  }
}



const asyncComponent = (load) => {
  @inject_unount
  class AsyncComponent extends React.Component {
    state = { Component: null }

    componentDidMount() {
      if (this.state.Component) return;
      // 在高阶组件 DidMount 时才去执行网络加载步骤
      load().then(({ default: Component }) => {
        // 代码加载成功，获取到了代码导出的值，调用 setState 通知高阶组件重新渲染子组件
        this.setState({
          Component,
        })
      }).catch((err) => {
        console.error(`Cannot load component in <AsyncComponent />`);
        throw err;
      });
    }

    render() {
      const { Component } = this.state;
      // component 是 React.Component 类型，需要通过 React.createElement 生产一个组件实例
      // return Component ? React.createElement(component) : null;
      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent
}


const Home = () => <Button className="hello">Home</Button>;


const routes = [
  {
    path: '/',
    component: asyncComponent(() => import(/* webpackChunkName: "Root" */'../pages/Root')),
    exact: true
  },
  {
    path: '/home',
    component: Home
  },
  /**
   *  码管理 start
   */
  {
    path: '/codeapply',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageApplication" */'../pages/CodeManagement/CodeSendingMangement/CodePackageApplication/index')),
    exact: true,
  },
  {
    path: '/codeauditor',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageAudit" */'../pages/CodeManagement/CodeSendingMangement/CodePackageAudit/index')),
    exact: true,
  },
  {
    path: '/codeapplyandauditor/codeApplication',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeApplicationMangement" */'../pages/CodeManagement/CodeApplicationMangement')),
    exact: true,
    name: '申请码'
  },
  {
    path: '/codetagConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig')),
    exact: true,
  },
  {
    path: '/codetagConfig/create',
    component: asyncComponent(() => import(/* webpackChunkName: "CreateCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/create')),
    exact: true,
  },
  {
    path: '/codetagConfig/createCompose',
    component: asyncComponent(() => import(/* webpackChunkName: "CreateCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/create/indexCompose')),
    exact: true,
  },

  {
    path: '/codetagConfig/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "EditCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/edit')),
    exact: true,
  },
  {
    path: '/codetagConfig/editCompose',
    component: asyncComponent(() => import(/* webpackChunkName: "EditCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/edit/indexCompose')),
    exact: true,
  },  
  /** 内部 */
  {
    path: '/SystemLogin',
    component: asyncComponent(() => import(/* webpackChunkName: "SystemLogin" */'../pages/SystemLogin/SystemLogin')),
    exact: true,
  },
  {
    path: '/codetempConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeTempConfig" */'../pages/CodeTempConfig/index')),
    exact: true,
  },
  {
    path: '/codetempConfig/create',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeTempConfigCreateOrEdit" */'../pages/CodeTempConfig/CreateOrEdit')),
    exact: true,
  },
  {
    path: '/codetempConfig/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeTempConfigCreateOrEdit" */'../pages/CodeTempConfig/CreateOrEdit')),
    exact: true,
  },
  {
    path: '/dataisvUser',
    component: asyncComponent(() => import(/* webpackChunkName: "ISVAccount" */'../pages/ResourceDistribution/ISVAccount/ISVAccount')),
    exact: true,
  },
  {
    path: '/dataisvUser/allocation',
    component: asyncComponent(() => import(/* webpackChunkName: "EnterpriseAllocation" */'../pages/ResourceDistribution/ISVAccount/EnterpriseAllocation')),
    exact: true,
    name: '企业分配'
  },
  {
    path: '/dataisvUser/whitelist',
    component: asyncComponent(() => import(/* webpackChunkName: "WhitelistSetting" */'../pages/ResourceDistribution/ISVAccount/WhitelistSetting')),
    exact: true,
    name: '白名单设置'
  },
  

  /** 内部 */
  {
    component: asyncComponent(() => import(/* webpackChunkName: "NoMatch" */'../pages/NoMatch')),
  },
];

const codeRoutes = [
  {
    path: '/',
    component: asyncComponent(() => import(/* webpackChunkName: "ExtractionCodePackage" */'../pages/Ucode/ExtractionCode/ExtractionCodePackage')),
    exact: true,
  },
  

  {
    component: asyncComponent(() => import(/* webpackChunkName: "NoMatch" */'../pages/NoMatch')),
  },
]



// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
function RouteWithSubRoutes({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        // pass the sub-routes down to keep nesting
        <Component {...props} />
        // <Component {...props} name={name}></Component>
      )}
    />
  );
}

const AppRouter = () => (
  <div style={{ width: '100%', height: '100%' }}>
    {/* <HashRouter> */}
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
    {/* </HashRouter> */}
  </div>
);

const CodeRouter = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Switch>
      {codeRoutes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  </div>
);




export { AppRouter, routes, CodeRouter };

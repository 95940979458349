import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Input, Button, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { updateCodeParamsDetailDialogStatus } = iceStarkData.store.get('commonAction')?.ui;
const { systemLogin } = iceStarkData.store.get('commonAction')?.users;
import JSEncrypt from '@/libs/encrypt.js';
import './SystemLogin.scss';
import logoIMg from './../../assets/jiahaologo.png';
const required = (text) => (value) => {
  return (!value || (value + '').trim() === '' ? text : undefined);
  // return (value || typeof value === 'number' ? undefined : '请填写备注');
}

const requiredAccount = required('请输入账号');
const requiredPassword = required('请输入密码');


@withRouter
@connect(
  state => ({

  }),
  {
    systemLogin: systemLogin.REQUEST
  }
)
class SystemLogin extends React.Component {

  componentDidMount() {
    document.onkeydown = (e) => {
      if (!e)
        e = window.event;//火狐中是 window.event
      if ((e.keyCode || e.which) == 13) {
        if(this.refs.submit){
          this.refs.submit.handleClick();
        }
        
      }
    }
  }

  componentDidUpdate() {

  }

  login = (values) => {
    const { systemLogin, history } = this.props;
    let { password ,account} = values;

    
    var encrypt  = new JSEncrypt();
    encrypt.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQqJ4SyujGCKwQvNzsKVMcoSQnoE60zKSPoUen7c+FRqJqineEFNhk7Hc8qWfZDbzMtIAMeg78+SgiOJ1es+KOIUOncaN0dae34QP5AdmORAm1AUXG8osmCe54SU0cnnIwS6L6RE6OIiFZzf//Eo3blf1x3sBLc6WZ/KDSqRLKCwIDAQAB`);
    var encrypted = encrypt.encrypt(password);
    let pwdObj = {account,password:encrypted};

    systemLogin({ body: pwdObj, history });
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="SystemLogin">
        <div className='media'></div>
        <div className="main-block">
          <img className="img"src={logoIMg}/>
          {/* 码管理平台登录 */}
          <div className="title">嘉豪SCRM平台登录</div>
          <div className="form">
            <FormGroupList>
              <FormGroupField
                name="account"
                label="帐号"
                component={Input}
                validate={[requiredAccount]}

              ></FormGroupField>

              <FormGroupField
                name="password"
                label="密码"
                type="password"
                component={Input}
                validate={[requiredPassword]}
              ></FormGroupField>

            </FormGroupList>
          </div>
          <Button ref="submit" type="submit" onClick={handleSubmit(this.login)}>登录</Button>
        </div>
        {/* <iframe style={{width: '100%', height: 1200}} src="">

        </iframe> */}
      </div>
    )
  }
}

let SystemLoginForm = reduxForm({
  form: 'SystemLoginForm', // a unique identifier for this form
  enableReinitialize: true,
})(SystemLogin)

export default SystemLoginForm;
